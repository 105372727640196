export const getCookieValue = function (cookieName: string): any | null {
  let thirdPartyCookie = {}
  if (typeof document !== 'undefined') {
    document.cookie.split(`; `).reduce((total, currentCookie) => {
      const item = currentCookie.split(`=`);
      const storedKey = item[0];
      const storedValue = item[1];
      return cookieName === storedKey ? decodeURIComponent(storedValue) : total;
    }, ``).split(`|`).map((cookie) => {
      thirdPartyCookie = {
        ...thirdPartyCookie,
        [cookie.split(':')[0]]: cookie.split(':')[1]
      }
    })
  }
  return thirdPartyCookie
}