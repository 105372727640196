import React, { useState, createContext } from 'react'

export const SearchContext = createContext<{
  searchState: any
  propertyType: any
  location: string[]
  transactionType: string
  setSearch: (param: any) => void
  unsetSearch: (param: any) => void
  setPropertyType: (param: any) => void
  flushSearchState: () => void
}>({
  propertyType: {},
  searchState: {},
  location: [],
  transactionType: 'buy',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsetSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPropertyType: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  flushSearchState: () => {},
})

export const SearchProvider = (props) => {
  const initialState = { propertyType: {}, location: [], transactionType: 'buy' }
  const [searchState, setSearchState] = useState(initialState)

  const setPropertyType = ({ name, value }: { name: string; value: boolean }) => {
    if (value) {
      setSearchState(({ propertyType, ...rest }) => ({
        ...rest,
        propertyType: { ...propertyType, [name]: value },
      }))
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setSearchState(({ propertyType: { [name]: unused, ...restPropertyType }, ...rest }) => ({
        ...rest,
        propertyType: restPropertyType,
      }))
    }
  }

  const setSearch = ({ name, value }: { name: string; value: string }) => {
    setSearchState(({ [name]: oldName, ...oldState }) => 
      value
        ? {
            ...oldState,
            [name]: value,
          }
        : {
          ...oldState,
          [name]: value,
        }
    )
  }

  const flushSearchState = () => setSearchState(initialState)

  const providerValue = { searchState, setSearch, setPropertyType, flushSearchState }
  return <SearchContext.Provider value={providerValue}>{props.children}</SearchContext.Provider>
}
